import api, { setHeaderAuthorizationBasic, unsetHeaderAuthorizationBasic } from '@/api';
import { getCards } from '@/services/cards';
import { getOrders } from '@/services/orders';
import Vue from 'vue';
import Vuex from 'vuex';
import * as otherAuth from './auth';
import { IUser } from './models/auth';
import { IBank } from './models/banks';
import { IOrder } from './models/orders';
import * as auth from './services/auth';
import { getBanks } from './services/banks';
import { getFeaturedStores, getShopDirectory } from './services/merchants';

Vue.use(Vuex);

const localStorageKey = 'layup-me';

const getCachedMe = () => ({} as IUser);

const getInitialState = () => ({
  authorized: null,
  orders: null,
  me: getCachedMe(),
  loading: false,
  ordersWithPayments: null,
  cards: null,
  merchantRedirect: null as string | null,
  incompletePaymentPlan: null,
  debicheckDetails: null as any,
  newCard: null,
  shops: null as any | [],
  shopCategories: null as any | [],
  featuredStores: null as any | [],
  banks: [] as IBank[],
});

export default new Vuex.Store({
  state: getInitialState(),
  mutations: {
    setAuthorized(state, authorized) {
      // eslint-disable-next-line no-param-reassign
      state.authorized = authorized;
    },
    setOrders(state, orders) {
      // eslint-disable-next-line no-param-reassign
      state.orders = orders;
    },
    setOrdersWithPayments(state, ordersWithPayments) {
      // eslint-disable-next-line no-param-reassign
      state.ordersWithPayments = ordersWithPayments;
    },
    setMe(state, me) {
      if (me != null) {
        // Force update the ui when any of these fields change.
        ['email', 'username', 'apikey', 'idNumber', 'cellNumber'].forEach((uniqueField) => {
          if ((me as any)[uniqueField] === undefined) {
            // eslint-disable-next-line no-param-reassign
            (me as any)[uniqueField] = '';
          }
        });
      }
      // eslint-disable-next-line no-param-reassign
      state.me = {
        ...state.me,
        ...me,
      };
    },
    setCards(state, cards) {
      // eslint-disable-next-line no-param-reassign
      state.cards = cards;
    },
    setNewCard(state, card) {
      // eslint-disable-next-line no-param-reassign
      state.newCard = card;
    },
    setLoading(state, loading) {
      // eslint-disable-next-line no-param-reassign
      state.loading = loading;
    },
    setMerchantRedirect(state, redirectUrl) {
      // eslint-disable-next-line no-param-reassign
      state.merchantRedirect = redirectUrl;
      localStorage.setItem('merchantRedirect', redirectUrl);
    },
    logout(state) {
      Object.assign(state, getInitialState());
    },
    setIncompletePaymentPlan(state, incompletePaymentPlan) {
      state.incompletePaymentPlan = incompletePaymentPlan;
    },
    setDebicheckDetails(state, debicheckDetails) {
      state.debicheckDetails = debicheckDetails;
    },
    setShops(state, shops) {
      state.shops = shops;
    },
    setFeaturedStores(state, stores) {
      state.featuredStores = stores;
    },
    setShopCategories(state, shopCategories) {
      state.shopCategories = shopCategories;
    },
    setBanks(state, banks) {
      state.banks = banks;
    },
  },
  actions: {
    async tryLogin({ dispatch, commit, getters }): Promise<boolean> {
      const { me: cachedMe } = getters;

      try {
        const AUTH_TOKEN = localStorage.getItem(localStorageKey);
        if (AUTH_TOKEN != null && !AUTH_TOKEN.includes('{')) setHeaderAuthorizationBasic(AUTH_TOKEN);
        const me = await auth.getMe();
        if (me._id) {
          commit('setMe', me);
          commit('setAuthorized', true);
          return true;
        }
      } catch {
        //
      }
      commit('setAuthorized', false);
      return false;
    },
    async login({ commit }, user: IUser): Promise<any> {
      try {
        const { data } = await auth.login(user).catch(async (e) => {
          const response = await auth.loginBasic(user);
          const AUTH_TOKEN = btoa(`${user.username}:${user.password}`);
          setHeaderAuthorizationBasic(AUTH_TOKEN);
          localStorage.setItem(localStorageKey, AUTH_TOKEN);
          return response;
        });
        if (data.role === 'MERCHANT') {
          commit('setAuthorized', false);
          return {
            error:
              'It appears you are trying to sign into a Shopper’s account using a Merchant’s user accounts details. Please check your details and try again with a verified Shopper’s account’s details.',
            success: false,
          };
        }
        commit('setMe', data);
        commit('setAuthorized', true);
        return { success: true };
      } catch (err) {
        commit('setAuthorized', false);
        return { success: false };
      }
    },
    async getOrders({ commit }): Promise<void> {
      try {
        const orders = await getOrders();
        commit('setOrders', orders);
      } catch {
        // TODO:
      }
    },
    async getOrdersWithPayments({ commit }): Promise<void> {
      try {
        const orders = await getOrders({
          populate: 'plans,plans.payments,plans.payments.connectorData,plans.benefactor,plans.debicheckMandate',
        });

        const ordersWithPayments = await Promise.all(
          orders.filter((order) => order.plans != null && order.plans.length >= 1).map(async (order) => order),
        );
        ordersWithPayments.sort(
          (o: any, oo: any) => new Date(oo.createdAt).getTime() - new Date(o.createdAt).getTime(),
        );
        commit(
          'setOrdersWithPayments',
          ordersWithPayments.filter((o: any) => o.state !== 'EXPIRED'),
        );
      } catch {
        // TODO:
      }
    },
    async getOrdersWithOwnPayments({ getters, dispatch }): Promise<void> {
      const promises = [];
      if (!getters.me) {
        promises.push(dispatch('getMe'));
      }
      if (!getters.ordersWithPayments) {
        promises.push(dispatch('getOrdersWithPayments'));
      }
      await Promise.all(promises);
    },
    async getMe({ commit }): Promise<void> {
      try {
        const me = await auth.getMe();
        commit('setMe', me);
      } catch {
        // TODO:
      }
    },
    async logout({ commit }, callback?: () => {}): Promise<void> {
      commit('setLoading', true);
      localStorage.setItem(localStorageKey, '');
      otherAuth.destroyToken();
      unsetHeaderAuthorizationBasic();
      localStorage.clear();
      commit('logout');
      commit('setAuthorized', false);
      commit('setOrders', null);
      commit('setMe', null);
      commit('setOrdersWithPayments', null);
      commit('setCards', null);
      await auth.logout().catch(async (logoutError: any) => {
        // If logout failes try retrieve profile assume logout was successful
        try {
          await api.get('auth/me');
          throw logoutError;
        } catch (e: any) {
          if (e && e.response && e.response.status === 401) {
            // not able to retrieve profile, logout was successful.
          } else {
            throw e;
          }
        }
      });
      if (callback) {
        callback();
      }
    },
    async getCards({ commit }): Promise<void> {
      try {
        const cards = await getCards();
        commit('setCards', cards);
      } catch {
        // TODO:
      }
    },
    async getShopDirectory({ commit }) {
      const { shops, categories } = await getShopDirectory();
      commit('setShops', shops);
      commit('setShopCategories', categories);
    },
    async getFeaturedStores({ commit }) {
      const { shops, categories } = await getFeaturedStores();
      commit('setFeaturedStores', shops);
      commit('setShopCategories', categories);
    },
    setMe({ commit }, me) {
      commit('setMe', me);
    },
    setDebicheckDetails({ commit }, debicheckDetails) {
      commit('setDebicheckDetails', debicheckDetails);
    },
    async getBanks({ commit }) {
      const banks = await getBanks();
      const sortedBanks = banks.sort((a, b) => (a.name < b.name ? -1 : 1));
      commit('setBanks', sortedBanks);
    },
  },
  getters: {
    authorized: (state) => state.authorized,
    cards: (state) => state.cards,
    orders: (state) => state.orders,
    newCard: (state) => state.newCard,
    debicheckDetails: (state) => state.debicheckDetails,
    me: (state) => state.me,
    loading: (state) => state.loading,
    ordersWithPayments: (state) => state.ordersWithPayments,
    ordersWithOwnPayments: (state, getters) => {
      const ordersWithPayments = getters.ordersWithPayments as null | IOrder[];
      const me = state.me as null | IUser;
      if (!me || !ordersWithPayments) return null;

      return (ordersWithPayments as IOrder[]).map((order) => {
        if (!order.plans) return order;
        // eslint-disable-next-line no-param-reassign
        order.plans = order.plans.filter((plan) => plan.benefactorId === me._id);
        if (order.plans.length === 0) {
          // eslint-disable-next-line no-param-reassign
          delete order.plans;
        }
        return order;
      });
    },
    incompletePaymentPlan: (state) => state.incompletePaymentPlan,
    merchantRedirect: (state) => {
      if (!state.merchantRedirect) {
        return localStorage.getItem('merchantRedirect');
      }
      return state.merchantRedirect;
    },
    banks: (state) => state.banks,
    shops: (state) => state.shops,
    featuredStores: (state) => state.featuredStores,
    shopCategories: (state) => state.shopCategories,
  },
});
