import api from '@/api';
import type { IOrder, IOrderRequest } from '@/models/orders';

export async function getOrders(params?: object): Promise<IOrder[]> {
  const { data } = await api.get('/orders', { params });
  return (data.orders as IOrder[]) || [];
}

export async function getOrder(id: string): Promise<IOrder> {
  const { data } = await api.get(`/orders/${id}?populate=plans,payments`);
  return data as IOrder;
}

export async function attachOrder(id: string): Promise<IOrder> {
  const { data } = await api.get(`/orders/attach/${id}`);
  return data as IOrder;
}

export async function createOrder(order: IOrderRequest): Promise<IOrder> {
  const { data } = await api.post('/orders', order);
  return data as IOrder;
}

export async function onlineDebitOrderSetup(body: any): Promise<IOrder> {
  const { data } = await api.post('/debicheck', body);
  return data as any;
}

export async function updateOrder(data: any) {
  const order = {
    orderCancellationRequest: data.orderCancellationRequest,
    orderRefundRequest: data.orderRefundRequest,
    notifications: data.notifications,
    state: data.state,
    acceptedProposedCancellationTerms: data.acceptedProposedCancellationTerms,
    cancellationTerms: data.cancellationTerms,
    orderRefundAmount: data.orderRefundAmount,
    completedOrderRefundRequest: data.completedOrderRefundRequest,
  };
  return api.put(`orders/${data._id}`, order);
}
export async function getDebitOrderMandateDetails(id: any): Promise<any> {
  const { data } = await api.get(`/debicheck/${id}`);
  return data as any;
}

export async function getMandateList(id: any): Promise<any> {
  const { data } = await api.get(`/debicheck/list/${id}`);
  return data as any;
}

export async function simulateMandateResponse(id: string, status: 'Accepted' | 'Rejected'): Promise<any> {
  const { data } = await api.post(`/simulate/absa-callback/${id}`, { type: 1, status });
  return data as any;
}

export async function simulateMandatePayment(paymentId: string, status: '15' | '16' = '15'): Promise<any> {
  // COLLECTIONACCEPTED = "15"
  // COLLECTIONREJECTED = "16"
  const { data } = await api.post(`/simulate/process-response/${paymentId}`, { status });
  return data as any;
}

export async function cloneAndCreatedOrder(id: string, order: IOrderRequest): Promise<IOrder> {
  const { data } = await api.post(`/orders?clone=${id}`, order);
  return data as IOrder;
}

export async function getOrderCancellationPreviewFee(id: string) {
  const { data } = await api.get(`/orders/${id}/previewFee`);
  return data as any;
}
